@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/helpers";
@import "~bootstrap/scss/utilities/api";

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-size: cover;
}

$enable-negative-margins: true;

$font-family-sans-serif: "Nunito Sans", Arial;
$font-weight-base: 600;

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 6,
);

$font-sizes: map-merge(
  $font-sizes,
  (
    7: $font-size-base * 0.875,
    8: $font-size-base * 0.75,
    9: $font-size-base * 0.625,
    10: $font-size-base * 0.5,
  )
);

$utilities: map-merge(
  $utilities,
  (
    "height": (
      property: height,
      class: h,
      values: (
        25: 25%,
        30: 30%,
        50: 50%,
        75: 75%,
        100: 100%,
        auto: auto,
        "10r": $spacer * 2.5,
        "44r": $spacer * 11,
        "stat-card": $spacer * 22.5,
      ),
    ),
    "viewport-height": (
      property: height,
      class: vh,
      values: (
        25: 25vh,
        40: 40vh,
        50: 50vh,
        75: 75vh,
        100: 100vh,
      ),
    ),
    "font-size": (
      rfs: true,
      property: font-size,
      class: fs,
      values: $font-sizes,
    ),
    "margin": (
      responsive: true,
      property: margin,
      class: m,
      values:
        map-merge(
          $spacers,
          (
            auto: auto,
          )
        ),
    ),
    "margin-x": (
      responsive: true,
      property: margin-right margin-left,
      class: mx,
      values:
        map-merge(
          $spacers,
          (
            auto: auto,
          )
        ),
    ),
    "margin-y": (
      responsive: true,
      property: margin-top margin-bottom,
      class: my,
      values:
        map-merge(
          $spacers,
          (
            auto: auto,
          )
        ),
    ),
    "margin-top": (
      responsive: true,
      property: margin-top,
      class: mt,
      values:
        map-merge(
          $spacers,
          (
            auto: auto,
          )
        ),
    ),
    "margin-end": (
      responsive: true,
      property: margin-right,
      class: me,
      values:
        map-merge(
          $spacers,
          (
            auto: auto,
          )
        ),
    ),
    "margin-bottom": (
      responsive: true,
      property: margin-bottom,
      class: mb,
      values:
        map-merge(
          $spacers,
          (
            auto: auto,
          )
        ),
    ),
    "margin-start": (
      responsive: true,
      property: margin-left,
      class: ms,
      values:
        map-merge(
          $spacers,
          (
            auto: auto,
          )
        ),
    ),
    // Negative margin utilities
    "negative-margin":
      (
        responsive: true,
        property: margin,
        class: m,
        values: $negative-spacers,
      ),
    "negative-margin-x": (
      responsive: true,
      property: margin-right margin-left,
      class: mx,
      values: $negative-spacers,
    ),
    "negative-margin-y": (
      responsive: true,
      property: margin-top margin-bottom,
      class: my,
      values: $negative-spacers,
    ),
    "negative-margin-top": (
      responsive: true,
      property: margin-top,
      class: mt,
      values: $negative-spacers,
    ),
    "negative-margin-end": (
      responsive: true,
      property: margin-right,
      class: me,
      values: $negative-spacers,
    ),
    "negative-margin-bottom": (
      responsive: true,
      property: margin-bottom,
      class: mb,
      values: $negative-spacers,
    ),
    "negative-margin-start": (
      responsive: true,
      property: margin-left,
      class: ms,
      values: $negative-spacers,
    ),
    // Padding utilities
    "padding":
      (
        responsive: true,
        property: padding,
        class: p,
        values: $spacers,
      ),
    "padding-x": (
      responsive: true,
      property: padding-right padding-left,
      class: px,
      values: $spacers,
    ),
    "padding-y": (
      responsive: true,
      property: padding-top padding-bottom,
      class: py,
      values: $spacers,
    ),
    "padding-top": (
      responsive: true,
      property: padding-top,
      class: pt,
      values: $spacers,
    ),
    "padding-end": (
      responsive: true,
      property: padding-right,
      class: pe,
      values: $spacers,
    ),
    "padding-bottom": (
      responsive: true,
      property: padding-bottom,
      class: pb,
      values: $spacers,
    ),
    "padding-start": (
      responsive: true,
      property: padding-left,
      class: ps,
      values: $spacers,
    ),
    // Gap utility
    "gap":
      (
        responsive: true,
        property: gap,
        class: gap,
        values: $spacers,
      ),
    "row-gap": (
      responsive: true,
      property: row-gap,
      class: row-gap,
      values: $spacers,
    ),
    "column-gap": (
      responsive: true,
      property: column-gap,
      class: column-gap,
      values: $spacers,
    ),
  )
);

// Colours

//sizes
$premium-lock-bottom: 0;
$premium-lock-right: 0;
$premium-lock-margin: 6px;

$custom-colors: (
  "gray-100": $gray-100,
  "gray-200": $gray-200,
  "gray-300": $gray-300,
  "gray-400": $gray-400,
  "gray-500": $gray-500,
  "gray-600": $gray-600,
  "gray-700": $gray-700,
  "gray-800": $gray-800,
  "gray-900": $gray-900,
  "olitt-grey": $gray-900,
  "olitt-gray": $gray-900,
  "olitt-premium": $orange-400,
  "facebook": #1877f2,
  "instagram": #c13584,
  "shop-chart-button": #343434,
  "lime-green": #32ce32,
);

$theme-colors: map-merge($theme-colors, $custom-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
$utilities: map-merge(
  $utilities,
  (
    "color":
      map-merge(
        map-get($utilities, "color"),
        (
          values:
            map-merge(
              map-get(map-get($utilities, "color"), "values"),
              ($theme-colors)
            ),
        )
      ),
    "background-color": (
      property: background-color,
      class: bg,
      values: $theme-colors,
    ),
  )
);

$body-bg: $light;
$body-color: $gray-900;

.icon-rotate-90 {
  --webkit-transform: rotate(90deg);
  --moz-transform: rotate(90deg);
  --ms-transform: rotate(90deg);
  --o-transform: rotate(90deg);
  transform: rotate(90deg);
  display: inline-block;
}

.icon-rotate-180 {
  --webkit-transform: rotate(180deg);
  --moz-transform: rotate(180deg);
  --ms-transform: rotate(180deg);
  --o-transform: rotate(180deg);
  transform: rotate(180deg);
  display: inline-block;
}

@mixin gradient-bg() {
  background-image: -webkit-linear-gradient(
    to right,
    #ff00cc 0%,
    #333399 51%,
    #ff00cc 100%
  );
  background-image: linear-gradient(
    to right,
    #ff00cc 0%,
    #333399 51%,
    #ff00cc 100%
  );
}

.btn-ai {
  @include gradient-bg();
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out,
    background-position 0.5s ease-in-out !important;
  background-size: 200% auto;
}

.btn-ai:hover {
  background-position: right center;
}

.premium_lock-lg {
  color: $orange-400;
  font-size: 40px;
}

.premium_lock-sm {
  position: absolute;
  bottom: $premium-lock-bottom;
  right: $premium-lock-right;
  margin: $premium-lock-margin;
  color: $orange-400;
  font-size: 22px;
}

// SIZES
.sidebar-size {
  width: 18rem;
  height: 100vh;
}

.sidebar-hidden {
  margin-left: -18rem;
}

// ==> EDITOR
#wysiwyg-editor-image {
  position: absolute;
  top: -30px;
  left: 0;
  display: none;
}

#wysiwyg-editor-image a {
  background: #1266f1;
  pointer-events: auto;
  color: $white;
  padding: 5px;
}

#wysiwyg-editor-image a:hover {
  background: $gray-900;
}

// <== EDITOR

@media (min-width: 992px) {
  #wrapper {
    padding-top: 0;
    padding-left: 18rem;
  }

  #templates-side-nav {
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 18rem;
    height: 100vh;
  }

  #templates-side-nav .navbar-collapse {
    display: flex;
    align-items: flex-start;
    flex-grow: 0;
    width: 100%;
    margin-bottom: auto;
  }

  #templates-side-nav .navbar-collapse .navbar-nav {
    flex-direction: column;
    width: 100%;
  }
}

@media (max-width: 600px) {
  #vvveb-builder #add-section-box {
    left: 0;
    width: 100%;
    min-width: 0;
  }
}

.vh-70 {
  height: 70vh;
}

.vh-75 {
  height: 75vh;
}

.vh-80 {
  height: 80vh;
}

.vh-85 {
  height: 85vh;
}

.vh-90 {
  height: 90vh;
}

.btn.rounded-2 {
  border-radius: 0.5rem;
}

.btn.hover-grow {
  -webkit-transition-property: transform;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  /* box-shadow: 0 0 1px rgba(0, 0, 0, 0); */
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  transition-property: transform;
}

.btn.hover-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.btn.hover-underline-from-left {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0);
  position: relative;
  overflow: hidden;
}

.btn.hover-underline-from-left:before {
  -webkit-transition-property: right;
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 100%;
  bottom: 0;
  background: $yellow;
  height: 4px;
  transition-property: right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.icon-flipped {
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
}

.btn.hover-underline-from-left:hover:before,
.hvr-underline-from-left:focus:before,
.hvr-underline-from-left:active:before {
  right: 0;
}

@import "../../node_modules/font-awesome/css/font-awesome.min.css";
@import "grapesjs/dist/css/grapes.min.css";

.gjs-resizer-h {
  border-radius: 100%;
  border: 1px solid $blue !important;
}

.gjs-resizer-h-tl,
.gjs-resizer-h-br {
  cursor:
    url("../modules/website/assets/icons/cursor-tl.svg") 8 8,
    nwse-resize !important;
}

.gjs-resizer-h-tc,
.gjs-resizer-h-bc {
  cursor:
    url("../modules/website/assets/icons/cursor-tc.svg") 8 8,
    ns-resize !important;
}

.gjs-resizer-h-tr,
.gjs-resizer-h-bl {
  cursor:
    url("../modules/website/assets/icons/cursor-tr.svg") 8 8,
    nesw-resize !important;
}

.gjs-resizer-h-cl,
.gjs-resizer-h-cr {
  cursor:
    url("../modules/website/assets/icons/cursor-cl.svg") 8 8,
    ew-resize !important;
}

.gjs-mdl-container {
  z-index: 102 !important;
}

.gjs-pn-devices-c {
  left: 50%;
  transform: translateX(-50%);
}

.gjs-pn-views-container {
  margin-top: 40px;
  height: calc(100% - 40px);
  @include media-breakpoint-up(md) {
  }
}

.gjs-pn-views {
  margin-top: 40px;
}

.gjs-pn-options {
  right: 0;
}

.gjs-pn-commands {
  position: fixed;
  top: auto;
  bottom: 2px;
  left: 50%;
  width: 95%;
  height: 40px;
  transform: translateX(-50%);

  .gjs-pn-buttons {
    flex-direction: row;
    gap: 0.5rem;
  }

  @include media-breakpoint-up(md) {
    position: absolute;
    width: 30px;
    top: 25%;
    left: 0;
    margin-top: 40px;
    margin-left: 5px;
    transform: none;

    .gjs-pn-buttons {
      flex-direction: column;
      gap: 0.5rem;
    }
  }
}

.gjs-cv-canvas {
  left: 40px;
  right: 8px;
  width: auto;

  @include media-breakpoint-down(md) {
    width: auto;
    left: 8px;
    right: 8px;
  }
}

.btn-instagram {
  background: linear-gradient(
    87.1deg,
    #ffd522 -10.92%,
    #f1000b 48.02%,
    #b900b3 106.81%
  );
}

// Context/Pop-over Menu Styles

.ctx-container {
  background-color: $gray-800;
  color: $white;
  box-shadow: $box-shadow-lg;
  border: 1px solid rgba($blue-100, 0.24);
  color: $white;
  border-radius: $border-radius-lg;
  font-size: $font-size-sm;
  font-weight: $font-weight-normal;
  line-height: $line-height-sm;
  max-width: calc(100vw - 32px);
  opacity: 1;
  position: fixed;
  z-index: $zindex-dropdown;
}

.pop-up-container {
  @extend .ctx-container;
  border: none;
  width: 100%;
  position: absolute;

  @include media-breakpoint-up(md) {
    max-width: 600px;
  }

  .content,
  .pop-up-module {
    overflow-x: auto;
    max-width: 100%;
    max-height: 85vh;
  }
}

@import "../../node_modules/bootstrap/scss/bootstrap";

* {
  box-sizing: border-box;
}

.skeleton-loading {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

// Avatar
.avatar {
  z-index: 10;
}

.avatar__animated {
  transition: all 250ms ease;
}

.avatar__animated:hover {
  transform: translate(calc(0.625rem * -1));
}

.custom-avatar-style {
  z-index: 10;
}

.avatar-span {
  z-index: 20;
}

.avatar-img {
  z-index: 30;
}

.uppy-Dashboard--modal .uppy-Dashboard-inner {
  z-index: 1056 !important;
}

.uppy-Dashboard--modal .uppy-Dashboard-overlay {
  // right above bootstrap modal (1055)
  z-index: 1055 !important;
}

@import "~bootstrap/scss/bootstrap";
